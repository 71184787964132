exports.components = {
  "component---src-components-templates-story-template-story-template-jsx": () => import("./../../../src/components/templates/StoryTemplate/StoryTemplate.jsx" /* webpackChunkName: "component---src-components-templates-story-template-story-template-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-historie-jsx": () => import("./../../../src/pages/historie.jsx" /* webpackChunkName: "component---src-pages-historie-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-mnie-jsx": () => import("./../../../src/pages/o-mnie.jsx" /* webpackChunkName: "component---src-pages-o-mnie-jsx" */),
  "component---src-pages-oferta-jsx": () => import("./../../../src/pages/oferta.jsx" /* webpackChunkName: "component---src-pages-oferta-jsx" */)
}

