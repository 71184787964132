import React, { useState, useContext } from "react";

export const LocaleContext = React.createContext();

export const LocaleProvider = ({ children }) => {
  const [locale, setLocaleAbbreviation] = useState("pl-PL");
  
  const setLocale = (localeAbbreviation) => {
    setLocaleAbbreviation(localeAbbreviation);
  };

  return <LocaleContext.Provider value={{ locale, setLocale }}>{children}</LocaleContext.Provider>;
};

export const useLocale = () => {
  const localeAbbreviation = useContext(LocaleContext);

  return localeAbbreviation;
};